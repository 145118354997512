import "core-js/stable";
import "regenerator-runtime/runtime";
import "../scss/style.scss";

import barba from "@barba/core/dist/barba";
import select from "dom-select";
import throttle from "lodash.throttle";
import debounce from "lodash.debounce";
import SmoothScroll from "smooth-scroll/dist/smooth-scroll.min";
import Swiper from "swiper/swiper-bundle.min";
import { classy as cl } from "./helpers/classy";
import { scrollLock, scrollUnLock } from "./helpers/scrollLock";
import Spoilers from "./helpers/spoilers";
import videoResize from "./helpers/videoResize";
import LazyLoad from "lazyload";
import VanillaTilt from "vanilla-tilt";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import axios from "./helpers/axios";
import serialize from "form-serialize";
import { onFormieInitListener } from "./helpers/formie";

gsap.registerPlugin(ScrollTrigger);

(function (doc, win) {
  require("viewport-units-buggyfill").init();
  window.openModal = false;
  window.dataLayer = window.dataLayer || [];
  const app = {};

  const initContactFormSvelteApp = () => {
    if (document.getElementById("svelte-contact-form")) {
      const el = document.getElementById("svelte-contact-form");
      new contactForm({
        target: el,
      });
    }
  };
  initContactFormSvelteApp();

  const nav = function () {
    const burgers = select.all(".burger");
    const header = select(".header");
    const subMenus = select.all(".sub-menu", header);
    const nav = select(".nav", header);
    const backWrap = select(".brand-wrap", header);
    const back = select(".back", backWrap);
    let cover = select(".is-cover, .white-header");
    const message = select("#message");

    if (message) {
      const messageClose = select("#message .close");
      messageClose.addEventListener("click", function () {
        message.style.opacity = 0;

        setTimeout(() => {
          message.remove();
        }, 200);
      });
    }

    function resetRight() {
      nav.style.cssText = "";
      header.style.right = "";
    }

    function lock() {
      window.openModal = header;
      scrollLock(header, (w) => {
        nav.style.cssText = `right: ${w}px`;
        header.style.right = `${w}px`;
      });
    }

    function unlock() {
      window.openModal = false;
      scrollUnLock(() => {
        resetRight();
      });
    }

    if (nav) {
      nav.addEventListener("click", (ev) => {
        if (ev.target.classList.contains("nav")) {
          this.close();
        }
      });
    }

    cl(".barba-container").rmCl("hide");
    setTimeout(() => {
      cl(header).rmCl("loader-open");
    }, 2000);
    setTimeout(() => {
      cl("body").rmCl("to-load");
      cl(message).addCl("visible");
    }, 2500);

    burgers.forEach((burger) => {
      burger.addEventListener("click", function () {
        if (burger.classList.contains("open")) {
          unlock();
        } else {
          lock();
        }
        const nav2 = select("header > nav");
        const contactFormContainer = select("header > div.nav");

        nav2.style.display = null;
        select(".header .nav").style.cursor = null;
        contactFormContainer.style.opacity = 0;

        cl(header).toCl("open");
        cl(burgers).toCls("open");
      });
    });

    select.all(".sub-trig", header).forEach((trig, i) => {
      trig.addEventListener("click", () => {
        cl(subMenus[i]).addCl("active");
        cl(nav).addCl("sub-open");
      });
    });

    select.all(".sub-back", header).forEach((trig) => {
      trig.addEventListener("click", () => {
        cl(nav).rmCl("sub-open");
        cl(subMenus).rmCls("active");
      });
    });

    if (back) {
      back.addEventListener("click", (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (window.location.pathname.indexOf("/work/") > -1) {
          barba.go("/work");
        } else if (window.location.pathname.indexOf("/articles/") > -1) {
          barba.go("/articles");
        }
      });
    }

    this.close = function () {
      cl(header).rmCl("open");
      cl(burgers).rmCls("open");
      cl(subMenus).rmCls("active");
      cl(nav).rmCl("sub-open");
      unlock();
    };

    this.scroll = function () {
      const coverBottom = cover ? cover.getBoundingClientRect().bottom : null;
      if (
        header &&
        coverBottom !== null &&
        coverBottom < 80 &&
        cover.classList.contains("is-cover") &&
        !cover.classList.contains("is-article")
      ) {
        cl(header).addCl("reset-color");
      } else if (header.classList.contains("reset-color")) {
        cl(header).rmCl("reset-color");
      }
    };

    this.updateColor = function (parent) {
      cover = select(".is-cover, .white-header", parent);
      const container = parent || select("[data-page-color]");
      const color = cover && cover.dataset.cover ? cover.dataset.cover : null;

      cl(header).rmCl("reset-color");

      const pageColors =
        container && container.dataset.pageColor
          ? container.dataset.pageColor.split("|")
          : [];
      const pageColor = pageColors.length ? pageColors[0] : null;
      const pageBG = pageColors.length > 1 ? pageColors[1] : null;

      if (pageColors.length) {
        doc.body.style.color = pageColor;
        doc.body.style.backgroundColor = pageBG;
      } else {
        doc.body.style.color = "";
        doc.body.style.backgroundColor = "";
      }

      if (color && header) {
        header.style.color = color;
        cl(header).addCl("has-color");
      } else if (header) {
        header.style.color = "";
        cl(header).rmCl("has-color");
      }

      const segments = location.pathname.split("/");
      if (backWrap && back && segments.length > 2) {
        cl(backWrap).addCl("show-back");
      } else {
        cl(backWrap).rmCl("show-back");
      }
    };

    this.updateColor();
  };

  function activeNav() {
    const firstSegment = location.pathname.split("/")[1];
    const lastSegment = location.pathname.split("/").pop();

    if (window.location.pathname.indexOf("/work/") > -1) {
      document.getElementById('nav-back').innerHTML= 'More Work';
    } else if (window.location.pathname.indexOf("/articles/") > -1) {
      document.getElementById('nav-back').innerHTML= 'More Articles';
    }
console.log('segments: ' + firstSegment + ' ' +lastSegment);
    //console.log(firstSegment,lastSegment);
    select.all(".header a, .nav a").forEach(function (nav) {
      const linkSegments = nav.pathname.split("/");
      const linkFirstSegment =
        linkSegments.length > 1 && linkSegments[1] !== ""
          ? linkSegments[1]
          : false;
      const linkSecondSegment =
        linkSegments.length > 2 && linkSegments[2] !== ""
          ? linkSegments[2]
          : false;
      //console.log(linkSegments,linkFirstSegment, linkSecondSegment);
      if (firstSegment == "" && !linkFirstSegment && !linkSecondSegment)
        cl(nav).addCl("active");
      else if (linkSecondSegment && linkSecondSegment === lastSegment)
        cl(nav).addCl("active");
      else if (
        linkFirstSegment &&
        !linkSecondSegment &&
        linkFirstSegment === firstSegment
      )
        cl(nav).addCl("active");
      else cl(nav).rmCl("active");
    });
  }

  function smooth(el, offset, speed) {
    if (el) {
      const scroll = new SmoothScroll();
      scroll.animateScroll(el, false, {
        offset: offset || 200,
        updateURL: false,
        speed: speed || 600,
        durationMax: speed || 600,
      });

      if (app.nav) {
        setTimeout(() => {
          app.nav.scroll();
        }, 1100);
      }
    }
  }

  function setSmooth(parent) {
    select.all(".smooth-scroll", parent).forEach((anchor) => {
      anchor.addEventListener("click", (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (anchor.dataset.window) {
          smooth(window.innerHeight, 0, 1000);
        } else {
          const target = select(anchor.hash);
          if (target) smooth(target, 40, 1000);
        }
      });
    });
  }

  app.swipers = [];
  function swipers(parent) {
    app.swipers.forEach((sw) => sw.destroy());

    select.all(".swiper-regular", parent).forEach((sw) => {
      app.swipers.push(
        new Swiper(sw, {
          effect: sw.classList.contains("fade-effect") ? "fade" : "slide",
          speed: 1000,
          loop: true,
          autoplay: true,
          pagination: {
            type: "bullets",
            clickable: true,
            el: select(".swiper-pagination", sw),
          },
        })
      );
    });

    select.all(".swiper-cover", parent).forEach((sw) => {
      app.swipers.push(
        new Swiper(sw, {
          speed: 1000,
          effect: "fade",
          loop: true,
          autoplay: {
            delay: 5000,
          },
        })
      );
    });

    setTimeout(function () {
      app.swipers.forEach((sw) => sw.update());
    }, 200);
  }

  app.lazyload = null;
  function setLazy(parent) {
    if (app.lazyload) app.lazyload.destroy();
    const images = select.all("[data-src]", parent);
    app.lazyload = new LazyLoad(images);
  }

  app.tilts = null;
  function setTilts(parent) {
    //if (app.tilts) app.tilts.destroy();
    const tilts = select.all("[data-tilt]", parent);
    app.tilts = VanillaTilt.init(tilts);
  }

  function setParallax(el) {
    if (window.innerWidth > 767) {
      if (el.classList.contains("col-md-4")) {
        gsap.fromTo(
          el,
          {
            y: "30%",
          },
          {
            scrollTrigger: {
              trigger: el,
              scrub: 1,
              start: "top bottom",
              end: "bottom top",
            },
            y: "-10%",
            ease: "none",
          }
        );
      }

      if (el.classList.contains("every-fourth")) {
        gsap.fromTo(
          el,
          {
            y: "10%",
          },
          {
            scrollTrigger: {
              trigger: el,
              scrub: 0.5,
              start: "top bottom",
              end: "bottom top",
            },
            y: "-10%",
            ease: "none",
          }
        );
      }
    }
  }

  const parallaxes = function (parent) {
    this.destroy = function () {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
    this.destroy();
    const destroy = this.destroy;

    function movingHead() {
      if (window.innerWidth < 737) {
        return;
      }

      select.all(".moving-head", parent).forEach((h) => {
        const heading = h.children.length ? h.children[0] : null;
        // if (!h.classList.contains('single-one')) {
        //   h.appendChild(heading.cloneNode(true))
        // }
        const isCover = !!h.closest(".is-cover");
        const slower = !!h.closest(".slower");

        gsap.fromTo(
          h,
          {
            x: isCover ? "0%" : "20%",
          },
          {
            scrollTrigger: {
              trigger: h,
              scrub: 1,
              start: isCover ? "center center" : "top bottom",
              end: "bottom top",
            },
            x: slower ? "-20%" : "-100%",
            transformOrigin: "left center",
            ease: "none",
          }
        );
      });
    }

    function setWraps(children = []) {
      if (children.length) {
        children.forEach(setParallax);
      } else {
        select.all(".parallax-items", parent).forEach((wrap) => {
          wrap.children.forEach(setParallax);
        });
      }
    }

    movingHead();
    setWraps();
    this.setWraps = setWraps;

    this.resize = debounce(function () {
      destroy();
      setWraps();
      movingHead();
    }, 1000);
  };

  app.pagTilts = [];
  const pagination = function (parent) {
    const cont = select(".paginated", parent);
    let page = cont && cont.dataset.pagNext !== "false" ? 2 : 1;
    let canFetch = true;

    function fetchPage(pageNo, callback) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", window.location + "/p" + pageNo, true);
      xhr.responseType = "document";
      xhr.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200 && callback)
          callback(this.response);
      };
      xhr.send();
    }

    this.scroll = function () {
      if (cont && page > 1) {
        const contPos = cont.getBoundingClientRect().bottom;

        if (contPos < window.innerHeight * 1.5 && canFetch) {
          canFetch = false;

          fetchPage(page, function (resp) {
            const newCont = select(".paginated", resp);
            const elements = select.all(".pag-item", newCont);
            page =
              newCont && newCont.dataset.pagNext !== "false" ? page + 1 : 1;

            if (newCont && elements.length) {
              setLazy(newCont);
              elements.forEach(function (el) {
                cont.appendChild(el);
                setParallax(el);

                const tilt = select.all("[data-tilt]", el);
                if (tilt) app.pagTilts.push(VanillaTilt.init(tilt));
              });

              canFetch = true;
            } else {
              canFetch = false;
            }
          });
        }
      }
    };
  };

  function forms(parent) {
    select.all(".ajax-form", parent).forEach((form) => {
      const button = select("button", form);

      let posting = false;

      function reset() {
        posting = false;
        cl(button).rmCl("is-loading");
        form.classList.add("is-posted");
        setTimeout(() => {
          form.reset();
          form.forEach((f) => cl(f).rmCl("filled"));
          form.classList.remove("is-posted");
        }, 3000);
      }

      form.addEventListener("submit", (e) => {
        e.preventDefault();
        e.stopPropagation();
        cl(button).addCl("is-loading");

        if (!posting) {
          posting = true;
          axios
            .post(window.location.href, serialize(form))
            .then(({ data }) => {
              reset();
              if (!data.success) {
                console.log(data);
              }
            })
            .catch((e) => {
              reset();
              console.log(e);
            });
        }
      });
    });
  }

  function quote(parent) {
    select.all(".block-quote", parent).forEach((q) => {
      const hoverElement = select(".hover-element", q);
      q.addEventListener("mouseover", () => {
        cl(q).addCl("is-hovered");
      });
      q.addEventListener("mouseleave", () => {
        cl(q).rmCl("is-hovered");
      });

      if (hoverElement) {
        q.addEventListener("mousemove", (ev) => {
          const rect = q.getBoundingClientRect();
          const x = ev.clientX - rect.left;
          const y = ev.clientY - rect.top;
          hoverElement.style.transform = `translate(${x}px, ${y}px)`;
        });
      }
    });
  }

  function outBarba() {
    app.nav = new nav();
  }

  app.videRes = null;
  function onBarba(parent) {
    cl("body").rmCl("is-empty");
    activeNav();
    if (parent) {
      swipers(parent);
      setSmooth(parent);
      setLazy(parent);
      setTilts(parent);
      quote(parent);
      forms(parent);
      app.parallaxes = new parallaxes(parent);
      app.pagination = new pagination(parent);
      app.spoilers = new Spoilers(parent);
      app.videRes = new videoResize(".has-cover-video");

      parent
        .querySelector(".typeform-modal-trigger")
        .addEventListener("click", (e) => {
          e.preventDefault();
          const nav2 = select("header > nav");
          const contactFormContainer = select("header > div.nav");
          const burgers = select.all(".burger");
          const header = select(".header");

          nav2.style.display = "none";
          select(".header .nav").style.cursor = "auto";

          contactFormContainer.style.opacity = 1;

          cl(header).toCl("open");
          cl(burgers).toCls("open");
        });
    }
  }

  doc.addEventListener("DOMContentLoaded", function () {
    outBarba();
    onFormieInitListener();

    win.addEventListener(
      "scroll",
      throttle(function () {
        if (app.nav) app.nav.scroll();
        if (app.pagination) app.pagination.scroll();
      }, 50)
    );

    win.addEventListener(
      "resize",
      throttle(function () {
        // if (app.nav) app.nav.close();
        if (app.videRes) app.videRes.resize();
        if (app.parallaxes) app.parallaxes.resize();
      }, 300)
    );

    barba.init({
      timeout: 5000,
      prefetchIgnore: true,
      transitions: [
        {
          from: {
            custom() {
              return window.openModal === false;
            },
          },
          name: "transition",
          leave({ current }) {
            cl(".header").rmCl("has-color");
            cl(current.container).addCl("hide");
            const done = this.async();
            setTimeout(() => {
              done();
            }, 300);
          },
        },
      ],
    });

    barba.hooks.beforeEnter(({ trigger }) => {
      if (!trigger.classList.contains("back")) {
        window.scrollTo(0, 0);
      }
    });

    barba.hooks.enter(({ next }) => {
      if (app.nav) {
        app.nav.updateColor(next.container);
        app.nav.close();
      }
    });

    barba.hooks.afterEnter(({ next }) => {
      onBarba(next.container);
      select.all("video", next.container).forEach((v) => {
        v.play();
      });

      setTimeout(() => {
        cl(next.container).rmCl("hide");
      }, 10);
    });

    barba.hooks.after(({ trigger }) => {
      if (trigger === "barba") {
        window.scrollTo(0, barba.history.previous.scroll.y);
      }
      // for GTAG or GA
      // window.dataLayer.push({
      //   "event": "pageview",
      //   "page": {
      //     "path": window.location.pathname,
      //     "title": document.title
      //   }
      // });
    });
  });
})(document, window);
