export const onFormieInitListener = () => {
  //Input field floating labels and contact form reload after submission
  document.addEventListener("onFormieInit", (e) => {
    const Formie = e.detail.formie;
    const contactUsform = Formie.getFormByHandle("contactUs");
    const contactUsformEl = document.querySelector(contactUsform.formId);
    if (contactUsformEl) {
      contactUsformEl.addEventListener("onAfterFormieSubmit", (e) => {
        e.preventDefault();
        setTimeout(() => {
          location.reload();
        }, 4000);
      });
    }

    document.querySelectorAll("input").forEach((el) => {
      const labels = document.getElementsByTagName("LABEL");
      for (var i = 0; i < labels.length; i++) {
        if (labels[i].htmlFor != "") {
          let elem = document.getElementById(labels[i].htmlFor);
          if (elem) elem.label = labels[i];
        }
      }

      el.addEventListener("focus", (e) => {
        if (e) {
          document.getElementById(el.id).label.classList.add("focused");
          el.classList.add("focused");
        }
      });

      el.addEventListener("blur", (e) => {
        if (e) {
          document.getElementById(el.id).label.classList.remove("focused");
          el.classList.remove("focused");
        }
      });

      el.addEventListener("input", (e) => {
        if (e.target.value) {
          document.getElementById(el.id).label.classList.add("has-value");
          el.classList.add("has-value");
        } else {
          document.getElementById(el.id).label.classList.remove("has-value");
          el.classList.remove("has-value");
        }
      });
    });
  });
};
